import request from '@/utils/request';
import {ModelStateErrors} from '@/models/application/ModelStateErrors';
import {RegisteredResultViewModel} from '@/models/viewmodels/restaurant/RegisteredResultViewModel';
import {RegisterRequestViewModel} from '@/models/viewmodels/restaurant/RegisterRequestViewModel';

export const getRegistration = (dateStr: string): Promise<RegisteredResultViewModel> =>
    (request({
        url: `/Restaurant/GetRegistered/${dateStr}`,
        method: 'get'
    }) as unknown) as Promise<RegisteredResultViewModel>;

export const getRegistrations = (startDateStr: string): Promise<Array<RegisteredResultViewModel>> =>
    (request({
        url: `/Restaurant/GetRegistrations/${startDateStr}`,
        method: 'get'
    }) as unknown) as Promise<Array<RegisteredResultViewModel>>;

export const postRegistration = (
    data: RegisterRequestViewModel
): Promise<RegisteredResultViewModel | ModelStateErrors> =>
    (request({
        url: `/Restaurant/PostRegistration`,
        method: 'post',
        data
    }) as unknown) as Promise<RegisteredResultViewModel | ModelStateErrors>;

export const cancelRegistration = (
    data: RegisterRequestViewModel
): Promise<RegisteredResultViewModel | ModelStateErrors> =>
    (request({
        url: `/Restaurant/CancelRegistration`,
        method: 'post',
        data
    }) as unknown) as Promise<RegisteredResultViewModel | ModelStateErrors>;

export const postMenuUpdate = (formData: FormData) =>
    request({
        url: `/Restaurant/PostNewMenu`,
        method: 'post',
        data: formData
    });
