





























import {Vue, Component} from 'vue-property-decorator';
import {to, hasClaim} from '@/utils';
import {postMenuUpdate} from '@/api/restaurant';
import {ApplicationModule} from '@/store/modules/application';
import { UserModule } from '@/store/modules/user';

@Component({})
export default class extends Vue {
    private menuFile: File | null = null;

    private mounted() {
        const { claims } = UserModule;
        if (!claims || !Array.isArray(claims) || claims.length === 0 || !hasClaim(claims, 'MyPyrop_RestaurantMenu', 'Update')) {
            this.$router.replace({ path : '/restaurant/menu'});
        }
    }

    async handleClickUpdateMenu(): Promise<void> {
        if (this.menuFile?.name !== 'MenuRestaurant.md') {
            return;
        }

        ApplicationModule.setLoading(true);
        const formData = new FormData();
        formData.append('image', this.menuFile, this.menuFile.name);
        await to(postMenuUpdate(formData));
        ApplicationModule.setLoading(false);
    }
}
